const getTenant = () => {
  let host = window.location.host
  if (host.indexOf('meetcharity.cn') !== -1 || host.indexOf('meetcharity.org') !== -1) {
    return { host: host, base_url: 'https://api.meetcharity.cn/app-api', tenant_id: 154, name: '慈缘' }
  } else if (host.indexOf('szshanjia.cn') !== -1) {
    return { host: host, base_url: 'https://api.meetcharity.cn/app-api', tenant_id: 155, name: '善加' }
  }
  return { host: host, base_url: 'https://api.meetcharity.cn/app-api', tenant_id: 154, name: '慈缘' }
}

const setTenantTag = () => {
  let tenant = getTenant()
  if (tenant.tenant_id === 154) {
    const faviconLinks = document.querySelectorAll('[href="/favicon.ico"]');
    faviconLinks.forEach(ele => {
      ele.setAttribute('href', '/favicon.ico')
    })
    document.title = tenant.name + "基金会";
  } else if (tenant.tenant_id === 155) {
    const faviconLinks = document.querySelectorAll('[href="/favicon.ico"]');
    faviconLinks.forEach(ele => {
      ele.setAttribute('href', '/favicon3.ico')
    })
    document.title = tenant.name + "基金会";
  } else {
    const faviconLinks = document.querySelectorAll('[href="/favicon.ico"]');
    faviconLinks.forEach(ele => {
      ele.setAttribute('href', '/favicon.ico')
    })
    document.title = "管理员系统";
  }
}
const setBaidu = () => {
  let tenant = getTenant()
  let token = ''
  if (tenant.tenant_id === 154) {
    token = '69dabe94ec4d03d7f77e32777dab6b07'
  } else if (tenant.tenant_id === 155) {
    token = '309da655b40e2708f64637ca75647e03'
  } else {
    token = '69dabe94ec4d03d7f77e32777dab6b07'
  }
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://hm.baidu.com/hm.js?' + token;
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
}

const config: {
  base_url: string
  result_code: number | string
  default_headers: string
  request_timeout: number
  tenant_id: number
} = {
  /**
   * api请求基础路径
   */
  base_url: getTenant().base_url,
  /**
   * 接口成功返回状态码
   */
  result_code: 200,
  /**
   * 默认租户
   */
  tenant_id: getTenant().tenant_id,

  /**
   * 接口请求超时时间
   */
  request_timeout: 30000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: 'application/json'
}

export { config, getTenant, setTenantTag, setBaidu }
